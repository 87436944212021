import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalDetails: null,
  addressDetails: null,
  workExperienceDetails: null,
  additionalComments: null,
  averageBankBalanceDetails: null,
  extraCosignerPersonalDetails: null,
  extraCosignerAddressDetails: null,
  extraCosignerWorkExperienceDetails: null,
  extraCosignerAdditionalComments: null,
  extraCosignerAverageBankBalanceDetails: null,
  cosignerPersonalDocuments: [],
  cosignerFinancialDocuments: [],
  cosignerOtherDocuments: [],
  cosignerSanctionApprovalDocuments: [],
  extraCosignerPersonalDocuments: [],
  extraCosignerFinancialDocuments: [],
  extraCosignerOtherDocuments: [],
  extraCosignerSanctionApprovalDocuments: [],
  familyExposureExtraCosigner: null,
  familyExposureCosigner: null,
  isSaving: {},
};

export const fetchCosignerDetails = createAction(
  "cosignerDetails/fetchCosignerDetails"
);
export const saveCosignerDetails = createAction(
  "cosignerDetails/saveCosignerDetails"
);

const cosignerDetailsSlice = createSlice({
  name: "cosignerDetails",
  initialState,
  reducers: {
    updatePersonalDetails(state, action) {
      const personalDetails = action.payload;
      return { ...state, personalDetails };
    },
    updateAddressDetails(state, action) {
      const addressDetails = action.payload;
      return { ...state, addressDetails };
    },
    updateWorkExperienceDetails(state, action) {
      const workExperienceDetails = action.payload;
      return { ...state, workExperienceDetails };
    },
    updateAdditionalComments(state, action) {
      const additionalComments = action.payload;
      return { ...state, additionalComments };
    },
    updateAverageBankBalanceDetails(state, action) {
      const averageBankBalanceDetails = action.payload;
      return { ...state, averageBankBalanceDetails };
    },
    updateExtraCosignerAverageBankBalanceDetails(state, action) {
      const extraCosignerAverageBankBalanceDetails = action.payload;
      return { ...state, extraCosignerAverageBankBalanceDetails };
    },
    updateExtraCosignerAddressDetails(state, action) {
      const extraCosignerAddressDetails = action.payload;
      return { ...state, extraCosignerAddressDetails };
    },
    updateExtraCosignerWorkExperienceDetails(state, action) {
      const extraCosignerWorkExperienceDetails = action.payload;
      return { ...state, extraCosignerWorkExperienceDetails };
    },
    updateExtraCosignerAdditionalComments(state, action) {
      const extraCosignerAdditionalComments = action.payload;
      return { ...state, extraCosignerAdditionalComments };
    },
    updateExtraCosignerPersonalDetails(state, action) {
      const extraCosignerPersonalDetails = action.payload;
      return { ...state, extraCosignerPersonalDetails };
    },
    updateFamilyExposureForCosigner(state, action) {
      const familyExposureCosigner = action.payload;
      return { ...state, familyExposureCosigner };
    },
    updateFamilyExposureForExtraCosigner(state, action) {
      const familyExposureExtraCosigner = action.payload;
      return { ...state, familyExposureExtraCosigner };
    },
    updateCosignerDocuments(state, action) {
      return {
        ...state,
        cosignerPersonalDocuments: action?.payload?.cosignerPersonal,
        cosignerFinancialDocuments: action?.payload?.cosignerFinancial,
        cosignerOtherDocuments: action?.payload?.cosignerOther,
        cosignerSanctionApprovalDocuments:
          action?.payload?.cosignerSanctionApprovalDocuments,
        extraCosignerPersonalDocuments: action?.payload?.extraCosignerPersonal,
        extraCosignerFinancialDocuments:
          action?.payload?.extraCosignerFinancial,
        extraCosignerOtherDocuments: action?.payload?.extraCosignerOther,
        extraCosignerSanctionApprovalDocuments:
          action?.payload?.extraCosignerSanctionApprovalDocuments,
      };
    },
    updateIsSaving(state, action) {
      const { formId, status } = action.payload;
      const isSaving = {
        [formId]: status,
      };
      return { ...state, isSaving };
    },
  },
});

export const {
  updatePersonalDetails,
  updateAddressDetails,
  updateWorkExperienceDetails,
  updateAdditionalComments,
  updateAverageBankBalanceDetails,
  updateExtraCosignerPersonalDetails,
  updateExtraCosignerAddressDetails,
  updateExtraCosignerAdditionalComments,
  updateExtraCosignerWorkExperienceDetails,
  updateExtraCosignerAverageBankBalanceDetails,
  updateCosignerDocuments,
  updateFamilyExposureForCosigner,
  updateFamilyExposureForExtraCosigner,
  updateIsSaving,
} = cosignerDetailsSlice.actions;
export default cosignerDetailsSlice.reducer;
